@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap");

:root {
  --color-bg: rgb(47, 62, 70);
  --color-bg-alt: rgb(53, 79, 82);
  --color-primary: rgb(132, 169, 140);
  --color-primary-alt: rgba(82, 121, 111, 0.4);
  --color-white: rgb(218, 218, 218);
  --color-light: rgba(202, 210, 197, 0.6);

  --bg-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAZlBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABpshDWAAAAInRSTlMAAAECAwQGBwgJCgwNDg8REhMUFRYXGBkbHB0eHyAjJCUnTiXhiAAAAHFJREFUeNplzMUBhUAUQ9Hg7u7Qf5HA5M1XsstZXBh/gxGF0HMdKChbX/++JOT7RHHHLSPY1T54/BUIsMpb+DUoWVZ+gVu6MenxBe58dKojwF5TsCzgqR7LAvbC3i1jQEhn9mGXS2YqcBPI/Dom/O0BF9UMBvRJ/iE6AAAAAElFTkSuQmCC");

  --transition: all 400ms ease;

  --container-width-lg: 80%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Rubik", sans-serif;
  line-height: 1.7;
  color: var(--color-white);
  background-color: var(--color-bg);
  background-image: var(--bg-image);
  transition: background-color 400ms ease;
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 300;
  transition: var(--transition);
}

p {
  transition: var(--transition);
}

h1 {
  font-size: 3rem;
}

h5 {
  font-size: 1.25rem;
}

section {
  margin-top: 6rem;
}

section > h2,
section > h5 {
  text-align: center;
  color: var(--color-light);
}

section > h2 {
  font-size: 2rem;
  color: var(--color-primary);
  margin-bottom: 3rem;
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover,
a:focus {
  color: var(--color-white);
}

svg {
  transition: none;
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover,
.btn:focus {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.icon {
  color: var(--color-primary);
  font-size: 2rem;
  transition: var(--transition);
}

.icon:hover,
.icon:focus {
  color: var(--color-white);
}

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section > h2 {
    margin-bottom: 2rem;
  }
}

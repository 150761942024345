footer {
  background: var(--color-primary);
  padding: 3rem 0;
  text-align: center;
  font-size: 0.9rem;
  margin-top: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

footer li, footer h4 {
  color: var(--color-bg);
  cursor: pointer;
}

.footer__logo {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  display: inline-block;
  transition: var(--transition);
}

.footer__logo:hover,
.footer__logo:focus {
  color: var(--color-white);
}

.permalinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto 2rem;
}

.permalinks > * {
  transition: var(--transition);
}

.permalinks > *:hover,
.permalinks > *:focus
{
  color: var(--color-white);
}

.footer__socials {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

a {
  color: var(--color-bg)
}

.footer__socials a {
  background: var(--color-bg);
  color: var(--color-primary);
  padding: 0.8rem;
  border-radius: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  margin-bottom: 1rem;
}

.footer__socials a:hover,
.footer__socials a:focus {
  background: transparent;
  color: var(--color-bg);
  border-color: var(--color-bg);
}

.footer__copyright {
  margin-bottom: 4rem;
  color: var(--color-bg);
}

@media screen and (max-width: 600px) {
  .permalinks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.5rem;
    margin: 0 1rem 1rem 1rem;
  }
  
  .footer__socials {
    margin: 1rem;
    gap: 1.5rem;
    flex-wrap: wrap;
  }

  .footer__socials a{
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
  }
}
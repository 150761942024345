header {
  padding-top: 3rem;
  overflow: hidden;
  height: 100vh;
}

.header__container {
  margin: -1.5rem auto 0 auto;
  text-align: center;
  position: relative;
}

.header__name {
  color: var(--color-primary);
  transition: var(--transition)
}

.header__theme-slider {
  color: var(--color-bg);
  position: fixed;
  right: 7.75rem;
  top: 1.55rem;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.header__theme-slider__toggle{
  color: var(--color-bg);
  width: 4rem;
  height: 2rem;
  padding: .5rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--color-primary);
  transition: var(--transition);
}

.header__theme-slider__selector{
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(2rem*0.8);
  height: calc(2rem*0.8);
  border-radius: 50%;
  position: relative;
  right: -3.8rem;
  background: var(--color-white);
  transition: var(--transition);
}

.call-to-action {
  margin: 1rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  top: 3rem;
}

.header__socials::before {
  content: '';
  width: 2px;
  height: 4rem;
  background: var(--color-primary);
}

.pfp-container {
  background: linear-gradient(var(--color-primary), var(--color-primary-alt), rgba(82, 121, 111, 0.08));
  max-width: 24rem;
  width: 80%;
  max-height: 32rem;
  margin: 2rem auto 0 auto;
  border-radius: 2rem;
  overflow: hidden;
  padding: 2rem 0 0 0;
  transition: var(--transition);
}

.pfp {
  width: 100%;
}

.scroll__down {
  font-size: 2rem;
  position: absolute;
  right: -2.5rem;
  bottom: 7.2rem;
  transform: rotate(90deg);
  cursor: pointer;
}

.scroll__down span:nth-child(even) {
  color: var(--color-light);
  transition: var(--transition);
}

.scroll__down span:nth-child(odd) {
  color: var(--color-primary);
  transition: var(--transition);
}

.scroll__down span:hover,
.scroll__down span:focus {
  color: var(--color-white);
}

.scroll__down__portfolio {
  color: var(--color-primary);
  position: absolute;
  bottom: 7.4rem;
  right: -3.75rem;
}

.scroll__down__portfolio>h3 {
  transition: var(--transition);
  color: var(--color-primary);
}

.scroll__down__portfolio>h3:hover,
.scroll__down__portfolio>h3:focus {
  color: var(--color-white);
  transform: scale(1.1);
  transform: translateX(1rem)
}

@media screen and (max-width: 1024px) {
  
  header {
    height: max-content;
  }
  .header__socials {
    left: 0;
    bottom: 9.2rem;
  }

  .scroll__down {
    bottom: 11.6rem;
  }

  .scroll__down__portfolio {
    bottom: 11.7rem;
    right: -3.5rem;
  }

    .header__theme-slider {
    right: 4.1rem;
    }
}

@media screen and (max-width: 600px) {

  .pfp-container {
    width: 80%;
  }

  .header__theme-slider {
      right: 2.5rem;
    }

  .header__socials,
  .scroll__down {
    display: none;
  }
}